import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

// bg celular
import BackgroundSMCelular from '../../assets/images/background-sm-convenio-pet-celular.png'
import BackgroundMDCelular from '../../assets/images/background-md-convenio-pet-celular.png'
import BackgroundLGCelular from '../../assets/images/background-lg-convenio-pet-celular.png'
import BackgroundXLCelular from '../../assets/images/background-xl-convenio-pet-celular.png'

// bg mulher
import BackgroundSMMulher from '../../assets/images/background-sm-convenio-pet-mulher.png'
import BackgroundMDMulher from '../../assets/images/background-md-convenio-pet-mulher.png'
import BackgroundLGMulher from '../../assets/images/background-lg-convenio-pet-mulher.png'
import BackgroundXLMulher from '../../assets/images/background-xl-convenio-pet-mulher.png'

// bg cachorro
import BackgroundSMCachorro from '../../assets/images/background-sm-convenio-pet-cachorro.png'
import BackgroundMDCachorro from '../../assets/images/background-md-convenio-pet-cachorro.png'
import BackgroundLGCachorro from '../../assets/images/background-lg-convenio-pet-cachorro.png'
import BackgroundXLCachorro from '../../assets/images/background-xl-convenio-pet-cachorro.png'

// bg filhote
import BackgroundSMFilhote from '../../assets/images/background-sm-convenio-pet-filhote.png'
import BackgroundMDFilhote from '../../assets/images/background-md-convenio-pet-filhote.png'
import BackgroundLGFilhote from '../../assets/images/background-lg-convenio-pet-filhote.png'
import BackgroundXLFilhote from '../../assets/images/background-xl-convenio-pet-filhote.png'

export const SectionWrapper = styled.section`

`

export const Card = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 245px;
  border: 1px solid ${grayscale[200]};
  padding: 16px;
  border-radius: 8px;
  @media ${device.tablet} {
    svg {
      width: 86px !important;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Step = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${orange.extra};
`

export const ImgGrid = styled.div`
    position: relative;
    display: grid;
    grid-template-rows: repeat(6, auto);
    grid-gap: 23px;

    @media ${device.desktopLG} {
      grid-template-columns: repeat(6, auto);
      grid-template-rows: repeat(6, auto);
    }

    @media ${device.desktopXL} {
      grid-template-columns: repeat(12 , 1fr);
      grid-template-rows: repeat(12 , auto);
    }

  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }

  .background-content {
    border-radius: 36px;
    .background-with-text {
      border-radius: 36px;
      padding: 21px;
      background-repeat: no-repeat;
      @media ${device.tablet} {
        padding: 33px;
      }
      @media ${device.desktopXL} {
        padding: 33px;
      }
    }
  }

  .grid-content-1 {
    background-color: ${orange.extra};
    height: 324px;
    @media ${device.tablet} {
      height: 587px;
    }
    @media ${device.desktopLG} {
      height: 100%;
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 4;
    }
    @media ${device.desktopXL} {
      height: 100%;
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 4;
    }
    .background-with-text {
      background-image: url(${BackgroundSMCelular});
      background-position: center bottom;
      @media ${device.tablet} {
        background-image: url(${BackgroundMDCelular});
      }
      @media ${device.desktopLG} {
        background-image: url(${BackgroundLGCelular});
      }
      @media ${device.desktopXL} {
        background-image: url(${BackgroundXLCelular});
      }
    }
  }
  .grid-content-2 {
    background-color: ${grayscale[100]};
    height: 196px;
    @media ${device.tablet} {
      height: 319px;
    }
    @media ${device.desktopLG} {
      height: 218px;
    }
    @media ${device.desktopXL} {
      height: 311px;
    }
    background-position: center bottom;
  }
  .grid-content-3 {
    background-color: ${grayscale[100]};
    height: 319px;
    margin-top: 36px;
    @media ${device.tablet} {
      margin-top: 24px;
      height: 319px;
    }
    @media ${device.desktopLG} {
      height: 368px;
    }
    @media ${device.desktopXL} {
      height: 524px;
    }
    .background-with-text {
      background-image: url(${BackgroundSMMulher});
      background-position: center bottom;
      @media ${device.tablet} {
        background-image: url(${BackgroundMDMulher});
        background-position: right bottom;
      }
      @media ${device.desktopLG} {
        background-image: url(${BackgroundLGMulher});
        background-position: right bottom;
      }
      @media ${device.desktopXL} {
        background-image: url(${BackgroundXLMulher});
        background-position: right bottom;
      }
    }
  }
  .grid-content-4 {
    background-color: ${grayscale[100]};
    height: 319px;
    @media ${device.tablet} {
      height: 319px;
    }
    @media ${device.desktopLG} {
      height: 264px;
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 4;
      grid-row-end: 7;
    }
    @media ${device.desktopXL} {
      height: 319px;
      grid-column-start: 1;
      grid-column-end: 8;
      grid-row-start: 4;
      grid-row-end: 7;
    }
    .background-with-text {
      background-image: url(${BackgroundSMCachorro});
      background-position: left bottom;
      @media ${device.tablet} {
        background-image: url(${BackgroundMDCachorro});
      }
      @media ${device.desktopLG} {
        background-image: url(${BackgroundLGCachorro});
      }
      @media ${device.desktopXL} {
        background-image: url(${BackgroundXLCachorro});
      }
    }
  }
  .grid-content-5 {
    background-color: ${grayscale[100]};
    height: 319px;
    @media ${device.tablet} {
      height: 319px;
    }
    @media ${device.desktopLG} {
      height: 264px;
      grid-column-start: 6;
      grid-column-end: 7;
      grid-row-start: 4;
      grid-row-end: 7;
    }
    @media ${device.desktopXL} {
      height: 319px;
      grid-column-start: 8;
      grid-column-end: 13;
      grid-row-start: 4;
      grid-row-end: 7;
    }
    .background-with-text {
      background-image: url(${BackgroundSMFilhote});
      background-position: right bottom;
      @media ${device.tablet} {
        background-image: url(${BackgroundMDFilhote});
      }
      @media ${device.desktopLG} {
        background-image: url(${BackgroundLGFilhote});
      }
      @media ${device.desktopXL} {
        background-image: url(${BackgroundXLFilhote});
      }
    }
  }

  .cat {
    position: relative;
    @media ${device.desktopLG} {
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 4;
    }
    @media ${device.desktopXL} {
      grid-column-start: 6;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 4;
    }
    img {
      position: absolute;
      bottom: 292px;
      right: 0;
      @media ${device.tablet} {
        border-bottom-right-radius: 161px;
        bottom: 243px;
      }
      @media ${device.desktopLG} {
        border-bottom-right-radius: 133px;
        bottom: 315px;
      }
      @media ${device.desktopXL} {
        border-bottom-right-radius: 166px;
        bottom: 442px;
      }
    }
  }

  .selo {
    position: absolute;
    right: 0;
    z-index: 3;
    top: -15px;
    width: 53px;
    @media ${device.tablet} {
      right: 0;
      top: -56px;
      width: 93px;
    }

    @media ${device.desktopLG} {
      right: 0;
      top: -49px;
    }

    @media ${device.desktopXL} {
      width: 120px;
      right: -52px;
    }
  }
`
