import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import QrCodeSeguroPet from './assets/images/qrcode-seguro-pet-modal.png'

import { Modal } from 'src/components/Modal'

import {
  SeuMelhorAmigoMereceViverBem,
  PorqueEscolherOPlanoPetDoInter,
  PlanoDeSaudePetIdealParaOSeuMelhorAmigo,
  OConvenioPetFuncionaAssim,
  ComoContratar,
  QuerReceberDicasEBeneficiosExclusivos,
  Faq,
} from './sections/_index'

import { Wrapper } from './style'
import useDomReady from 'src/hooks/window/useDomReady'

const SeguroPet = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-24 lh-30"><span class="text-orange--extra fw-600">Baixe nosso app,</span> abra sua conta gratuita e comece para contratar o Plano de Saúde Pet </span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos. Aí é só baixar o app do Inter, abrir a sua conta digital e criar a sua comunidade.'
        qrCode={QrCodeSeguroPet}
      />
    </Modal>
  )
  return (
    <Wrapper>
      {openModal}
      <Layout pageContext={pageContext}>
        <SeuMelhorAmigoMereceViverBem setOpenModal={setIsOpen} />
        <PorqueEscolherOPlanoPetDoInter />
        <PlanoDeSaudePetIdealParaOSeuMelhorAmigo setOpenModal={setIsOpen} />
        <OConvenioPetFuncionaAssim />
        <ComoContratar setOpenModal={setIsOpen} />
        <QuerReceberDicasEBeneficiosExclusivos />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default SeguroPet
