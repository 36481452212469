import React, { ReactNode } from 'react'

import PetDog from 'inter-frontend-svgs/lib/orangeds/MD/pet-dog'
import Wallet from 'inter-frontend-svgs/lib/orangeds/MD/wallet'
import Doctor from 'inter-frontend-svgs/lib/orangeds/MD/doctor'

import jsonData from './assets/data/data.json'
import ScrollTo from 'src/components/ScrollTo'

import { grayscale } from 'src/styles/colors'
import * as S from './style'

interface IIconsList {
  [index: string]: ReactNode;
}

interface IPlanoPet {
  icon: string;
  title: string;
  description: string;
}

const OConvenioPetFuncionaAssim = () => {
  const planoPet: IPlanoPet[] = jsonData
  const icons: IIconsList = {
    petDog: <PetDog color={grayscale[500]} width={24} height={24} />,
    wallet: <Wallet color={grayscale[500]} width={24} height={24} />,
    doctor: <Doctor color={grayscale[500]} width={24} height={24} />,
  }

  return (
    <S.SectionWrapper className='py-5'>
      <div className='container'>
        <div className='row'>
          <S.ConvenioPet className='col-12'>
            <div className='col-lg-5 offset-lg-6 px-0 right-padding'>
              <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-400 text-grayscale--500 mt-lg-4'>O Plano Pet <span className='d-lg-block'>funciona assim</span></h2>
              <div>
                {planoPet.map((plano: IPlanoPet) => (
                  <div key={plano.icon} className='d-flex mb-4'>
                    <div className='mr-3'>
                      {icons[plano.icon]}
                    </div>
                    <div>
                      <h3 className='fs-16 lh-20 fs-md-20 lh-md-25 fw-600 text-grayscale--500 text-sora mb-3' dangerouslySetInnerHTML={{ __html: plano.title }} />
                      <p className='fs-14 lh-16 fs-md-18 lh-md-22 fw-400 text-grayscale--400 text-inter mb-0' dangerouslySetInnerHTML={{ __html: plano.description }} />
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <ScrollTo
                  to='#planos-seguro-pet'
                  section='dobra_04'
                  sectionName='O Plano Pet funciona assim'
                  elementName='Ver planos'
                >
                  <S.Button
                    className='btn btn-orange--extra fs-12 lh-17 fw-700 rounded-2 text-none mw-100'
                    title='Ver planos'
                    aria-label='Conferir planos de Saúde Pet'
                  >
                    Ver planos
                  </S.Button>
                </ScrollTo>
              </div>
            </div>
          </S.ConvenioPet>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default OConvenioPetFuncionaAssim
