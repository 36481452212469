import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`

`

export const Card = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 245px;
  border: 1px solid ${grayscale[200]};
  padding: 16px;
  border-radius: 8px;
  @media ${device.tablet} {
    svg {
      width: 86px !important;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Step = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${orange.extra};
`
