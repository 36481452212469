import React from 'react'
import FAQ from 'src/components/Faq'
import pageContext from '../../pageContext.json'
import { FaqSection } from './style'

const Faq = () => {
  return (
    <FaqSection id='perguntas-frequentes' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'>Perguntas frequentes</h3>
          </div>
          <FAQ id='seguro-saude' columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} className='px-0' answerData={pageContext.structuredData.faq} />
        </div>
      </div>
    </FaqSection>
  )
}

export default Faq
