import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

import BackgroundSM from '../../assets/images/background-mobile-o-plano-pet.png'
import BackgroundMD from '../../assets/images/background-tablet-o-plano-pet.png'
import BackgroundLG from '../../assets/images/background-lg-o-plano-pet.png'
import BackgroundXL from '../../assets/images/background-xl-o-plano-pet.png'

export const SectionWrapper = styled.section`
  h2 {
    margin-bottom: 32px;
    @media ${device.desktopXL} {
      margin-top: 56px !important;
    }
  }
`

export const ConvenioPet = styled.div`
  display: flex;
  align-items: end;
  min-height: 830px;
  border-radius: 24px;
  padding: 24px;
  background-color: ${grayscale[100]};
  background-image: url(${BackgroundSM});
  background-repeat: no-repeat;
  background-size: contain;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    min-height: 1076px;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
    background-size: initial;
    min-height: 716px;
    align-items: start;
    padding-right: 0 !important;
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
  }
`

export const Button = styled.a`
  height: 48px !important;
  width: 266px !important;

  @media ${device.tablet} {
    width: 100% !important;
  }
`
