import React, { useRef, useEffect } from 'react'
import { animated, useTrail } from 'react-spring'

// bg gato
import BackgroundSMGato from '../../assets/images/background-sm-convenio-pet-gato.png'
import BackgroundMDGato from '../../assets/images/background-md-convenio-pet-gato.png'
import BackgroundLGGato from '../../assets/images/background-lg-convenio-pet-gato.png'
import BackgroundXLGato from '../../assets/images/background-xl-convenio-pet-gato.png'

// import SeloPlanosAPartir from '../../assets/images/selo-planos-a-partir.png'

import useScroll from 'src/hooks/window/useScroll'
import isVisible from 'src/utils/isVisible'

import * as S from './style'

const fadeFrom = {
  config: {
    duration: 900,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const PorqueEscolherOPlanoPetDoInter = () => {
  const scroll = useScroll(300)

  const celularRef = useRef(null)
  const gatoRef = useRef(null)
  const mulherRef = useRef(null)
  const cachorroRef = useRef(null)
  const filhoteRef = useRef(null)

  const [ celularRefAnimation, setCelularRefAnimation ] = useTrail(7, () => (fadeFrom))
  const [ gatoRefAnimation, setGatoRefAnimation ] = useTrail(7, () => (fadeFrom))
  const [ mulherRefAnimation, setMulherRefAnimation ] = useTrail(7, () => (fadeFrom))
  const [ cachorroRefAnimation, setCachorroRefAnimation ] = useTrail(7, () => (fadeFrom))
  const [ filhoteRefAnimation, setFilhoteRefAnimation ] = useTrail(7, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(celularRef.current, -0.3)) {
      setCelularRefAnimation((fadeTo))
    }
    if (isVisible(gatoRef.current, -0.3)) {
      setGatoRefAnimation((fadeTo))
    }
    if (isVisible(mulherRef.current, -0.3)) {
      setMulherRefAnimation((fadeTo))
    }
    if (isVisible(cachorroRef.current, -0.3)) {
      setCachorroRefAnimation((fadeTo))
    }
    if (isVisible(filhoteRef.current, -0.3)) {
      setFilhoteRefAnimation((fadeTo))
    }
  }, [ scroll ])

  return (
    <S.SectionWrapper className='pt-0 pb-0 pt-md-5 pb-md-5 pb-xl-0'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 fw-400 text-grayscale--500 mb-3'>Por que escolher o convênio pet do Inter?</h2>
          </div>
          <div className='col-12 order-md-last mb-4 mb-md-0'>
            <S.ImgGrid>
              <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/plano-pet-grafismo/image.webp' alt='Selo planos a partir pet' className='selo' />
              <div className='background-content grid-content-1'>
                <animated.div ref={celularRef} style={celularRefAnimation[0]} className='background-with-text h-100'>
                  <h3 className='fs-24 lh-30 fs-md-32 lh-md-32 fs-xl-40 lh-xl-50 fw-400 text-white font-sora text-center text-lg-left'><span className='d-lg-block'>Contratação</span> simplificada <span className='d-md-block d-lg-inline d-xl-block'>direto <span className='d-lg-block d-xl-inline'>no Super App</span></span></h3>
                </animated.div>
              </div>
              <div className='cat'>
                <animated.img ref={gatoRef} style={gatoRefAnimation[1]} src={BackgroundSMGato} alt='Gato malhado laranja deitado' className='d-block d-md-none' />
                <animated.img ref={gatoRef} style={gatoRefAnimation[1]} src={BackgroundMDGato} alt='Gato malhado laranja deitado' className='d-none d-md-block d-lg-none' />
                <animated.img ref={gatoRef} style={gatoRefAnimation[1]} src={BackgroundLGGato} alt='Gato malhado laranja deitado' className='d-none d-lg-block d-xl-none' />
                <animated.img ref={gatoRef} style={gatoRefAnimation[1]} src={BackgroundXLGato} alt='Gato malhado laranja deitado' className='d-none d-xl-block' />
                <div className='background-content grid-content-2'>
                  <animated.div ref={gatoRef} style={gatoRefAnimation[2]} className='background-with-text h-100'>
                    <h3 className='fs-24 lh-30 fs-md-32 lh-md-32 fs-xl-40 lh-xl-50 fw-400 text-grayscale--500 font-sora text-center'>Mais de 3800 <span className='d-md-block'>clínicas credenciadas</span></h3>
                  </animated.div>
                </div>
                <div className='background-content grid-content-3'>
                  <animated.div ref={mulherRef} style={mulherRefAnimation[3]} className='background-with-text h-100'>
                    <h3 className='fs-24 lh-30 fs-md-32 lh-md-32 fs-xl-40 lh-xl-50 fw-400 text-grayscale--500 font-sora text-left text-md-left d-lg-none'>Escolha como <span className='d-md-block d-lg-inline'>pagar: cartão de </span>crédito ou débito</h3>
                    <h3 className='fs-24 lh-30 fs-md-32 lh-md-32 fs-xl-40 lh-xl-50 fw-400 text-grayscale--500 font-sora text-left text-md-left d-none d-lg-block'>Escolha <span className='d-lg-block'>como pagar:</span> cartão de <span className='d-lg-block'>crédito ou</span> débito</h3>
                  </animated.div>
                </div>
              </div>
              <div className='background-content grid-content-4'>
                <animated.div ref={cachorroRef} style={cachorroRefAnimation[4]} className='background-with-text h-100 d-flex justify-content-end align-items-lg-center'>
                  <h3 className='fs-24 lh-30 fs-md-32 lh-md-32 fs-xl-40 lh-xl-50 fw-400 text-grayscale--500 font-sora text-right'>Preço único, <span className='d-block'>independente</span> da raça, idade <span className='d-block'>e porte</span></h3>
                </animated.div>
              </div>
              <div className='background-content grid-content-5'>
                <animated.div ref={filhoteRef} style={filhoteRefAnimation[5]} className='background-with-text h-100'>
                  <h3 className='fs-24 lh-30 fs-md-32 lh-md-32 fs-xl-40 lh-xl-50 fw-400 text-grayscale--500 font-sora d-lg-none'>Inter Seguros <span className='d-block'>+ PetLove</span></h3>
                  <h3 className='fs-24 lh-30 fs-md-32 lh-md-32 fs-xl-40 lh-xl-50 fw-400 text-grayscale--500 font-sora d-none d-lg-block'>Inter Seguros +<span className='d-lg-block'>Petlove</span></h3>
                </animated.div>
              </div>
            </S.ImgGrid>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default PorqueEscolherOPlanoPetDoInter
