import React, { useState, ChangeEvent } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import axios from 'axios'
import Estados from '../../../../../static/forms/state.json'
import { sendCDPFormData } from 'src/shared/helpers'
import * as URLS from 'src/config/api/Urls'
import CheckCircle from '@interco/icons/build-v4/orangeds/MD/check-circle'
import Canceled from '@interco/icons/build-v4/orangeds/MD/canceled'

import { CreditoPjFormContainer, Checkbox, Circle } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type EstadoType ={
  label: string;
  value: string | null;
}
interface IFormValues {
  nome: string;
  email: string;
  cpf: string;
  estado: string;
  cidade: string;
  petName: string;
  animalzinho: string;
}

function SeguroPetForm () {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ sendDatalayerEvent ] = useDataLayer()

  const formStatus = {
    idle: 'idle',
    loading: 'loading',
    sent: 'sent',
    error: 'error',
    filled: 'filled',
  }
  const [ status, setStatus ] = useState(formStatus.idle)
  const [ accept, setAccept ] = useState(false)
  const [ estado, setEstado ] = useState('')
  const [ animalzinho, setAnimalzinho ] = useState<string>('')

  function handleCheck () {
    setAccept(!accept)
  }

  const sendForm = async (data: IFormValues) => {
    setStatus(formStatus.loading)

    const formData = [ {
      campanha: 'Seguro Pet',
      cpfCnpj: data.cpf.replace(/\D/g, ''),
      nome: data.nome,
      email: data.email,
      aceite: accept,
      conteudo01: data.estado,
      conteudo02: data.cidade,
      conteudo03: data.petName,
      descricao: data.animalzinho,
    } ]

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setStatus(formStatus.sent)
      sendCDPFormData({ formName: 'Formulario credito de pj', cpf: data.cpf.replace(/\D/g, ''), email: data.email })
      sendDatalayerEvent({
        section: 'dobra_05',
        element_action: 'submit',
        element_name: 'Receber Dicas',
        section_name: 'Quer receber dicas e benefícios exclusivos?',
      })
    } catch (e) {
      setStatus(formStatus.error)
    }
  }

  const hasOptionSelected = (valueSelect: string) => {
    return valueSelect ? 'ativo' : ''
  }

  return (
    <>
      {status === formStatus.sent && (
        <CreditoPjFormContainer>
          <div className='col-12 '>
            <div className='col-12 text-center'>
              <h3 className='fs-32 lh-37 pt-4 text-grayscale--100'>Dados recebidos</h3>
              <Circle className='mx-auto' circleColor='#00AA4F'>
                <CheckCircle height={32} width={32} color='#ffffff' />
              </Circle>
              <p className='fs-18 lh-22 text-grayscale--100 mt-4'>
                Em breve entraremos em contato com você!
              </p>
            </div>
          </div>
        </CreditoPjFormContainer>
      )}
      {status === formStatus.error && (
        <CreditoPjFormContainer>
          <div className='col-12 '>
            <div className='col-12 text-center'>
              <h3 className='fs-32 lh-37 pt-4 text-grayscale--100'>
                Não foi possível concluir a solicitação
              </h3>
              <Circle className='mx-auto' circleColor='#F56A50'>
                <Canceled height={32} width={32} color='#ffffff' />
              </Circle>
              <p className='fs-18 lh-22 text-grayscale--100 mt-4'>
                Tente novamente mais tarde
              </p>
            </div>
          </div>
        </CreditoPjFormContainer>
      )}
      {(status === formStatus.idle || status === formStatus.loading) && (
        <CreditoPjFormContainer>
          <div className='d-flex flex-column'>
            <form className='form--default w-100' onSubmit={handleSubmit(sendForm)}>
              <div className='row d-flex justify-content-center '>
                <div className={`col-12 mb-3 position-relative ${errors.nome && 'input-error'}`}>
                  <label htmlFor='nome' className='fs-14 lh-18 d-block text-grayscale--100'>Nome</label>
                  <input
                    className='w-100'
                    ref={register({
                        required: 'Digite o seu nome',
                        validate: {
                          isName: (value: string) => Validations.name(value) || 'Por favor, digite o seu nome completo',
                        },
                      })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Como gosta de ser chamado?'
                  />
                  {errors.nome && <p className='fs-12 text-right mb-0'>{errors.nome.message}</p>}
                </div>

                <div className={`col-12 col-md-6 mb-3 position-relative ${errors.email && 'input-error'}`}>
                  <label htmlFor='email' className='fs-14 lh-18 d-block text-grayscale--100'>E-mail</label>
                  <input
                    className='w-100'
                    ref={register({
                        required: 'Por favor, digite seu e-mail',
                        validate: {
                          isEmail: (value: string) => Validations.isEmail(value) || 'Por favor, digite um e-mail válido',
                        },
                      })}
                    name='email'
                    id='email'
                    type='email'
                    placeholder='Seu e-mail de contato'
                  />
                  {errors.email && <p className='fs-12 text-right mb-0 '>{errors.email.message}</p>}
                </div>

                <div className={`col-12 col-md-6 mb-3 position-relative ${errors.cpf && 'input-error'}`}>
                  <label htmlFor='cpf' className='fs-14 lh-18 d-block text-grayscale--100'>CPF</label>
                  <input
                    className='w-100'
                    ref={register({
                        required: 'CPF obrigatório',
                        validate: {
                          cpf: (value: string) => Validations.cpf(value) || 'CPF inválido',
                        },
                      })}
                    name='cpf'
                    id='cpf'
                    type='text'
                    placeholder='Informe seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  { errors.cpf && <p className='fs-12 text-red--base pt-1 mb-0 text-right '>{errors.cpf.message}</p> }
                </div>

                <div className={`col-12 col-md-6 mb-3 position-relative ${errors.estado && 'input-error'}`}>
                  <label htmlFor='estado' className='fs-14 lh-18 d-block text-grayscale--100'>Estado</label>
                  <span className='select'>
                    <select
                      className={hasOptionSelected(estado)}
                      name='estado'
                      id='estado'
                      ref={register({
                    required: 'Selecione um estado',
                    })}
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => setEstado(event.currentTarget.value)}
                    >
                      {Estados.map((item: EstadoType) => (
                        <option value={item.value?.toUpperCase() || ''} key={item.label}>{item.label}</option>
                      ),
                    )}
                    </select>
                  </span>
                  {errors.estado && <p className='fs-12 text-right mb-0 '>{errors.estado.message}</p>}
                </div>

                <div className={`col-12 col-md-6 mb-3 position-relative ${errors.cidade && 'input-error'}`}>
                  <label htmlFor='cidade' className='fs-14 lh-18 d-block text-grayscale--100'>Cidade</label>
                  <input
                    className='w-100'
                    ref={register({
                        required: 'Cidade obrigatório',
                      })}
                    name='cidade'
                    id='cidade'
                    type='text'
                    placeholder='Cidade onde mora'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cidade', event.currentTarget.value)}
                  />
                  { errors.cidade && <p className='fs-12 text-red--base pt-1 mb-0 text-right '>{errors.cidade.message}</p> }
                </div>

                <div className={`col-12 col-md-6 mb-3 position-relative ${errors.petName && 'input-error'}`}>
                  <label htmlFor='petName' className='fs-14 lh-18 d-block text-grayscale--100'>Nome do seu pet</label>
                  <input
                    className='w-100'
                    name='petName'
                    id='petName'
                    type='text'
                    maxLength={100}
                    placeholder='Informe o nome do Pet'
                    ref={register({
                      required: 'Nome do Pet obrigatório',
                    })}
                  />
                  {errors.petName && <p className='fs-12 text-right mb-0 '>{errors.petName.message}</p>}
                </div>

                <div className={`col-12 col-md-6 mb-3 position-relative ${errors.animalzinho && 'input-error'}`}>
                  <label htmlFor='animalzinho' className='fs-14 lh-18 d-block text-grayscale--100'>Seu animalzinho é</label>
                  <span className='select'>
                    <select
                      className={hasOptionSelected(animalzinho)}
                      name='animalzinho'
                      id='animalzinho'
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => setAnimalzinho(event.currentTarget.value)}
                      ref={register({
                        required: 'Animalzinho obrigatório',
                      })}
                    >
                      <option value=''>Selecionar</option>
                      <option value='cachorro'>Cachorro</option>
                      <option value='gato'>Gato</option>
                    </select>
                  </span>
                  {errors.animalzinho && <p className='fs-12 text-right mb-0 '>{errors.animalzinho.message}</p>}
                </div>
              </div>
              <div className='row justify-content-center'>
                <div className='col-12 accept-terms'>
                  <Checkbox>
                    <input
                      name='aceite'
                      value={accept.toString()}
                      id='aceite'
                      type='checkbox'
                      className='form-input-check'
                      onChange={handleCheck}
                      checked={accept}
                    />
                    <label className='form-label-check text-grayscale--100' htmlFor='aceite'>
                      Li e concordo com os
                      <a
                        className='fw-700' title='Acessar Política de Privacidade do Inter'
                        href='https://marketing.bancointer.com.br/arquivos/pdf/seguros/termos-e-condicoes-VF.pdf'
                        target='_blank' rel='noreferrer'
                      >{' '}Termos e condições de uso{' '}
                      </a>
                      e com a
                      <a
                        className='fw-700' title='Acessar Política de Privacidade do Inter'
                        href='https://inter.co/politica-de-privacidade/privacidade/'
                        target='_blank' rel='noreferrer'
                      >{' '}Política de Privacidade do Inter.
                      </a>
                    </label>
                  </Checkbox>
                </div>
              </div>
              <div className='col-12 mt-2 mt-md-4 text-center px-md-0'>
                <button
                  type='submit' title='Receber Dicas'
                  disabled={!accept}
                  className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-grayscale--100 text-none mw-100'
                  aria-label='Receber dicas e benefícios exclusivos'
                >
                  {status === formStatus.loading ? 'Enviando...' : 'Receber Dicas'}
                </button>
              </div>
            </form>
          </div>
        </CreditoPjFormContainer>
      )}
    </>
  )
}
export default SeguroPetForm
