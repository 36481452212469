import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  h1 {
    @media ${device.tablet} {
      margin-bottom: 40px !important;
    }
  }

  .btn {
    height: 48px;
    @media ${device.tablet} {
      margin-top: 40px !important;
    }
  }

  .mini-title {
    font-family: 'Inter' !important;
  }
`

export const ImgGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 8px;

  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }

  .grid-image-1 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 7;
  }

  .grid-image-2 {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .grid-image-3 {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 7;
  }
`
