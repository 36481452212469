import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      escudoPix: imageSharp(fluid: {originalName: { regex: "/escudo-pix/" }}) {
        fluid(maxWidth: 226, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerSeuMelhorAmigoDogAndCat: imageSharp(fluid: {originalName: { regex: "/banner-seu-melhor-amigo-dog-and-cat/" }}) {
        fluid(maxWidth: 358, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerSeuMelhorAmigoCat: imageSharp(fluid: {originalName: { regex: "/banner-seu-melhor-amigo-cat/" }}) {
        fluid(maxWidth: 169, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerSeuMelhorAmigoDog: imageSharp(fluid: {originalName: { regex: "/banner-seu-melhor-amigo-dog/" }}) {
        fluid(maxWidth: 169, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerQuerReceberDicasEBeneficiosExclusivos: imageSharp(fluid: {originalName: { regex: "/banner-quer-receber-dicas-e-beneficios-exclusivos/" }}) {
        fluid(maxWidth: 296, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
