import React from 'react'
import jsonData from './assets/data/data.json'
import * as S from './style'
import slugify from 'slugify'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IPlanoDeSaudePet {
  plano: string;
  objetivo: string;
  preco: string;
  beneficos: string[];
  url: string;
  styles: {
    backgroundColor: string;
    textPrimaryColor: string;
    textSecondaryColor: string;
    buttonClasses: string;
  };
  ariaLabelCTA: string;
}

interface IPlanoDeSaudePetIdealParaOSeuMelhorAmigo {
  setOpenModal: (state: boolean) => void;

}

const PlanoDeSaudePetIdealParaOSeuMelhorAmigo = ({ setOpenModal }: IPlanoDeSaudePetIdealParaOSeuMelhorAmigo) => {
  const planoDeSaudePet: IPlanoDeSaudePet[] = jsonData
  const windowWidth = useWidth(100)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper className='pt-0 pb-0 pt-md-5 pb-md-5 pt-xl-0 bg-white' id='planos-seguro-pet'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-400 text-grayscale--500 mb-3'>Plano de Saúde Pet ideal para o <span className='d-lg-block d-xl-inline'>seu melhor amigo</span></h2>
            <p className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fw-400 text-grayscale--500 mb-md-0 font-sora'>Aqui no Inter, há duas opções de plano de saúde para cachorros e gatos.</p>
          </div>
          <div className='col-12'>
            <S.Planos>
              {planoDeSaudePet.map((plano: IPlanoDeSaudePet) => (
                <S.Plano key={plano.plano} backgroundColor={plano.styles.backgroundColor} className='d-lg-flex flex-column justify-content-lg-between'>
                  <div>
                    <div className='d-block align-items-left mb-md-4 mb-lg-5'>
                      <div className='mb-4'>
                        <h3 dangerouslySetInnerHTML={{ __html: plano.plano }} className='fs-24 lh-30 fs-md-32 lh-md-40 fw-600 font-sora mb-2' style={{ color: plano.styles.textPrimaryColor }} />
                        <p dangerouslySetInnerHTML={{ __html: plano.objetivo }} className='fs-20 lh-25 fs-lg-24 lh-lg-30 fw-400 font-sora mb-xl-0' style={{ color: plano.styles.textSecondaryColor }} />
                      </div>
                      <div className='mb-5 mb-lg-4'>
                        <span className='fs-40 lh-50 fw-600 font-sora' style={{ color: plano.styles.textPrimaryColor }}>R${plano.preco.split(',')[0]}<span className='fs-24 lh-30 fw-400 font-sora' style={{ color: plano.styles.textSecondaryColor }}>,{plano.preco.split(',')[1]}</span></span>
                      </div>
                    </div>
                    <div className='pb-3'>
                      {plano.beneficos.map((beneficio: string) => (
                        <p dangerouslySetInnerHTML={{ __html: beneficio }} key={slugify(beneficio)} className='fs-20 lh-25 fs-lg-24 lh-lg-30 fw-400 font-sora mb-4' style={{ color: plano.styles.textPrimaryColor }} />
                    ))}
                    </div>
                  </div>
                  <div>
                    <S.Button
                      className={plano.styles.buttonClasses}
                      title='Contratar'
                      aria-label={plano.ariaLabelCTA}
                      onClick={() => {
                        if (windowWidth < WIDTH_MD) {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: 'Contratar',
                            section_name: plano.plano,
                            redirect_url: plano.url,
                          })
                          window && window.location.replace(plano.url)
                        } else {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: 'Contratar',
                            section_name: plano.plano,
                          })
                          setOpenModal(true)
                        }
                      }}
                    >
                      Contratar
                    </S.Button>
                  </div>
                </S.Plano>
              ))}
            </S.Planos>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default PlanoDeSaudePetIdealParaOSeuMelhorAmigo
