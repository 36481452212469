import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

type Plano = {
  backgroundColor: string;
}

export const SectionWrapper = styled.section`

`

export const Planos = styled.div`
  gap: 24px;
  display: grid;
  grid-template-columns: auto;

  @media ${device.desktopLG} {
    grid-template-columns: repeat(3, 296px);
  }
  @media ${device.desktopXL} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Button = styled.button`
  height: 48px;
  min-width: 100%;
`

export const Plano = styled.div<Plano>`
  padding: 24px;
  border-radius: 36px;
  background-color: ${(props: Plano) => props.backgroundColor};

  @media ${device.tablet} {
    padding: 32px;
  }

  @media ${device.desktopLG} {
    height: 784px;
  }
  @media ${device.desktopXL} {
    height: 688px;
  }
`
