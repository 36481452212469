import styled from 'styled-components'
import { gray, grayscale, orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

type Circle = {
  circleColor: string;
}

export const CreditoPjFormContainer = styled.div`
  .form--default label {
    color: ${grayscale[100]} !important;
  }

  .input-error {
    label, p {
      color: #F56A50;
    }
    span.select{
      background-color: #FEF0ED;
    }
    input {
      background-color: #FEF0ED;
    }
    select{
      background-color: #FEF0ED !important;
    }
  }
  input{
    font-weight:700;
    padding: 16px;
    
    ::-webkit-input-placeholder{
      color:${grayscale[300]};
    }
    :focus{
      outline:none;
    }
  }
  span.select {
    background: ${grayscale[100]};
    border-radius: 4px;
    display: block;
    position: relative;
    outline: none;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0;
    height: 48px !important;
    .ativo{
      color: ${grayscale[500]};
    }
    &:after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      right: 30px;
      top: 14px;
      color: ${orange.extra}
    }
    select {
      appearance: none;
      background: ${grayscale[100]}; 
      border: 0;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      line-height: 17px;
      font-weight:700;
      padding: 16px;
      width: 100%;
      color:${grayscale[300]};
      text-overflow: ellipsis;
      padding-right: 50px;
      white-space: nowrap;
      :focus{
      outline:none;
      }
    }
  } 
  .accept-terms{
    > div{
      text-align:center;
      label{
        font-size: 14px;
        line-height: 17px;
        color: ${grayscale[500]};
        text-align:left;
        padding-top: 0; 
      }
    }
    .form-label-check{
      &::before {
        top:8px;
        @media ${device.tablet}{
          top: -2px;
        }
      }
    }
    .form-input-check{
      &:checked ~ label {
        &::after {
          top: 12px;
          @media ${device.tablet}{
            top: 2px;
          }
        }
      }
    }
  }
`

export const Checkbox = styled.div`
  margin: 10px 0;

  @media ${device.tablet} {
    text-align: left !important;
  }

  input {
    display: contents;
  }

  label {
    font-size: 12px !important;
  }

  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;

    &::before, &::after {
      display: block;
      position: absolute;
      content: '';
    }

    &::before {
      border: 2px solid ${gray['500']};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 2px;
      width: 20px;     
    } 
    
    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }

  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
    &::before {
      background: linear-gradient(7.83deg, ${orange.dark} 0%, ${orange.base} 100%);
      border: 1px solid ${orange.dark};
    }
    &::after {
      left: 7px;
      top: 5px;
      width: 6px;
      height: 10px;
      border: solid ${white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @media ${device.tablet} {
          top: 5px;
        }
      }
    }
  }
`

export const Circle = styled.div<Circle>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${(props: Circle) => props.circleColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`
