import React from 'react'
import pageQuery from '../../pageQuery'
import Img from 'gatsby-image'

import * as S from './style'
import SeguroPetForm from 'src/components/UI/Forms/SeguroPetForm'

const QuerReceberDicasEBeneficiosExclusivos = () => {
  const data = pageQuery()
  return (
    <S.SectionWrapper className='py-5 bg-grayscale--500'>
      <div className='container'>
        <div className='row align-items-lg-center'>
          <div className='col-12 col-lg-8'>
            <div className='mb-5'>
              <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-400 text-white mb-3'>Quer receber dicas e <span className='d-lg-block'>benefícios exclusivos?</span></h2>
              <p className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fw-400 text-white mb-md-0'>Oba! Preencha seus dados e aguarde nosso contato!</p>
            </div>
            <SeguroPetForm />
          </div>
          <div className='d-none d-lg-block col-12 col-lg-4'>
            <Img fluid={data.bannerQuerReceberDicasEBeneficiosExclusivos.fluid} alt='Cachorro preto sentado' />
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default QuerReceberDicasEBeneficiosExclusivos
