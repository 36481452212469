import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`

`

export const CardFlex = styled.div`
  position: relative;
  margin-top: 60px;

  img {
    position: absolute;
    right: 0;
    top: -15px;
    @media ${device.tablet} {
      right: 0;
      top: -56px;
    }

    @media ${device.desktopLG} {
      right: 0;
      top: -78px;
    }

    @media ${device.desktopXL} {
      right: -52px;
    }
  }

  gap: 24px;
  display: grid;
  grid-template-columns: auto;

  @media ${device.tablet} {
  grid-template-columns: repeat(2, auto);
  }

  @media ${device.desktopLG} {
    grid-template-columns: repeat(3, auto);
  }

  @media ${device.desktopXL} {
    grid-template-columns: repeat(6, auto);
  }
`

export const Card = styled.div`
  background-color: ${grayscale[400]};
  border-radius: 8px;
  padding: 24px;
  width: 312px;
  height: 220px;

  @media ${device.tablet} {
    width: 336px;
    height: 206px;
  }

  @media ${device.desktopLG} {
    width: 296px;
    height: 206px;
  }

  @media ${device.desktopXL} {
    width: 168px;
    height: 316px;
  }
`

export const Button = styled.div`
  margin-top: 60px;
  width: 100%;
  height: 48px;

  @media ${device.desktopLG} {
    width: 298px;
  }

  @media ${device.desktopXL} {
    width: 360px;
  }
`
