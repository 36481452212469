import React from 'react'
import dataJson from './assets/data/data.json'
import * as S from './style'
import slugify from 'slugify'
import SeloSeguroPet from '../../assets/images/selo-seguro-pet.png'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
interface IStepContratarSeguro {
  step: string;
  text: string;
}

interface IComoContratar {
  setOpenModal: (state: boolean) => void;

}

const ComoContratar = ({ setOpenModal }: IComoContratar) => {
  const stepContratarSeguro: IStepContratarSeguro[] = dataJson
  const windowWidth = useWidth(100)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper className='pt-5 pb-0 pt-md-5 pb-md-5 bg-grayscale--500'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-400 text-white mb-3'>Como contratar?</h2>
            <p className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fw-400 text-white mb-md-0'>Ativar seu Plano Pet é simples:</p>
          </div>
          <div className='col-12'>
            <S.CardFlex>
              <img src={SeloSeguroPet} alt='Selo seguro pet' className='' />
              {stepContratarSeguro.map((card: IStepContratarSeguro) => (
                <S.Card key={slugify(card.text)} className='d-flex flex-column'>
                  <div>
                    <span className='fs-48 lh-60 fw-400 text-white font-sora'>{card.step}</span>
                  </div>
                  <div>
                    <h3 className='fs-18 lh-22 fw-400 text-white mb-0' dangerouslySetInnerHTML={{ __html: card.text }} />
                  </div>
                </S.Card>
              ))}
            </S.CardFlex>
            <div className='d-flex justify-content-center'>
              <S.Button
                className='btn btn-orange--extra fs-12 lh-17 fw-700 rounded-2 text-none'
                title='Contratar agora'
                aria-label='contratar plano de Saúde Pet'
                onClick={() => {
                  if (windowWidth < WIDTH_MD) {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Contratar Agora',
                      section_name: 'Como contratar?',
                      redirect_url: 'https://intergo.app/5065d095',
                    })
                    window && window.location.replace('https://intergo.app/5065d095')
                  } else {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Contratar Agora',
                      section_name: 'Como contratar?',
                    })
                    setOpenModal(true)
                  }
                }}
              >
                Contratar agora
              </S.Button>
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default ComoContratar
